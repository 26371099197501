@use '@/styles/utils/mixins.scss' as *;

.popiaNotice {
  margin-top: 20px;
  font-size: 12px;
  color: $kiaMidnightBlack;

  .popiaLink {
    margin-top: 20px;
    font-size: 14px;
    display: inline-flex;
    font-weight: 600;
    color: $kiaMidnightBlack;
    text-decoration: underline;
    -webkit-transition: all 450ms ease-in-out;
    -moz-transition: all 450ms ease-in-out;
    -o-transition: all 450ms ease-in-out;
    -ms-transition: all 450ms ease-in-out;
    transition: all 450ms ease-in-out;
    &:hover {
      text-decoration: none;
    }
  }
}
