@use '@/styles/utils/mixins.scss' as *;
.brochureWrapper {
  background-color: $kiaCharlestonColor;
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 1;
        @include tab() {
          font-size: 48px;
          line-height: 1;
        }
        @include mob() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 1;
        @include tab() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleMd {
        font-size: 24px;
        line-height: 36px;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 1;
      }
    }
  }
  .btnCTA {
    padding: 12px 30px;
    @media screen and (max-width: 576px) {
      margin-right: auto;
    }
    span {
      display: block;
    }
  }
}
