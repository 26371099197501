@use '@/styles/utils/mixins.scss' as *;

.formContainer {
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: $kiaPolarWhiteTransparent;
  @media screen and (min-width: 1024px) {
    padding: 50px 20px 20px;
  }

  @media screen and (max-width: $maxTablet) {
    background-color: $kiaPolarWhite;
  }
}

.formLabel {
  font-size: 12px;
  line-height: 1.2;
  color: $kiaMidnightBlack;
  margin-bottom: 18px;
}
.formInput {
  border-color: $kiaSilver !important;
  height: auto !important;
  color: $kiaMidnightBlack;
  &::placeholder {
    color: $kiaMidnightBlack;
  }
}

select.formInput {
  option {
    border-radius: 0;
    color: $kiaMidnightBlack;
  }
}

.formInput:focus-visible,
.formInput[data-focus-visible] {
  border-color: $kiaMidnightBlack !important;
  box-shadow: 0px 1px 0px 0px $kiaMidnightBlack !important;
}
.floatingForm {
  position: relative;
  margin-bottom: 8px;

  .formInput {
    height: 48px;
    padding-top: 20px;
    padding-bottom: 4px;
    font-size: 16px;
    background: transparent;
    border-radius: 0;
    border-width: 0 0 1px 0;

    &::placeholder {
      color: transparent;
    }

    &:focus,
    &:not(:placeholder-shown) {
      & + .formLabel {
        transform: translateY(-24px) scale(0.85);
        color: $kiaMidnightBlack;
      }
    }
  }

  .formLabel {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #666;
    pointer-events: none;
    transition: all 0.2s ease-out;
    transform-origin: left top;
    z-index: 1;
    margin: 0;
    padding: 0;
  }
}
.btn {
  background-color: $kiaMidnightBlack;
  border: 1px solid $kiaMidnightBlack;
  color: $kiaPolarWhite;
  font-family: 'KiaSignature';
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  padding: 13px 30px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
  display: flex;
  text-align: center;
  border-radius: 0;
  width: max-content;
  height: auto;
  span {
    display: inline-block;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      width: 0;
      background-color: $kiaMidnightBlack;
      transition: width 0.3s;
    }
  }
  &:hover,
  &:active,
  &.active {
    background-color: $kiaMidnightBlack;
    border-color: $kiaMidnightBlack;
    color: $kiaMidnightBlack;
    span {
      &::before {
        width: 100%;
      }
    }
  }
  &.disabled,
  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &.btnSm {
    padding: 10px 24px;
  }
}

.openButton {
  position: absolute !important;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.closeButton {
  position: absolute !important;
  top: 20px;
  right: 20px;
  z-index: 1001;
}
